





















import { Component, Vue } from 'vue-property-decorator';
import { dashboardService } from '@/services/DashboardService';
import Header from '@/components/common/Header.vue';

@Component({
  components: { Header },
})
export default class UserManagement extends Vue {
  data: any;
  type = '';
  mounted() {
    this.type = this.$route.params.type || '';
    dashboardService
      .getData()
      .then((data: any) => {
        this.data = data;
      })
      .catch(() => {});
  }
  goTo(path: string) {
    this.type = path;
    this.$router.push(`/user-management/${path}`).catch(() => {});
  }
}
